@import url('https://fonts.googleapis.com/css?family=Montserrat:500&display=swap');
@import '../constants/styles.scss';

body:not(.ace_editor) {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
		'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	font-size: 14px;
}
h1 {
	font-size: 3em;
	font-weight: 500;
}
h2 {
	font-size: 2.5em;
	font-weight: 500;
}
h3 {
	font-size: 2em;
	font-weight: 500;
}
h4 {
	font-size: 1.5em;
	font-weight: 500;
}
p {
	font-size: 1em;
	font-weight: 400;
}
/*--------------JSON Editor--------------*/

.jsoneditor-contextmenu {
	z-index: 2;
}

.ace_editor {
	min-height: 400px;
}

div.jsoneditor-tree {
	min-height: 400px;
	background-color: #fff;
}

/*---------------------------------------*/
.centerFullScreen {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	height: 100vh;
}

.ant-layout.ant-layout-has-sider > .ant-layout {
	overflow: hidden;
}

.ant-card-body {
	flex: 1;
	padding: 0;
}
.overview-style {
	.header {
		// display: flex;
		// justify-content: space-between;
		// align-items: center;
		margin: 0 0 20px 0;
		p {
			margin: 0;
			&:nth-child(1) {
				font-size: 22px;
				font-weight: 500;
				letter-spacing: 0.55;
			}
			&:nth-child(2) {
				font-size: 10px;
				font-weight: 500;
				letter-spacing: 0.3;
			}
		}
	}
	// .ant-col {
	// 	margin-bottom: 20px;
	// }
	// @media (min-width: 576px) {
	// 	.ant-col-sm-16 {
	// 		padding-right: 20px;
	// 	}
	// }
	// @media (min-width: 768px) {
	// 	.ant-col-md-14 {
	// 		padding-right: 20px;
	// 	}
	// 	.ant-col-md-6 {
	// 		padding-right: 20px;
	// 	}
	// }
}

.modal {
	.ant-modal-header {
		border-bottom: 0;
		padding: 16px 24px 0;
	}
	.ant-modal-footer {
		background: $primaryFadeColor;
		border-top: 0;
	}
}

.detail-page {
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 0 10px 0;

		p {
			margin: 0;
			&:nth-child(1) {
				font-size: 22px;
				font-weight: 500;
				letter-spacing: 0.55;
			}
			&:nth-child(2) {
				font-size: 12px;
				font-weight: 500;
				letter-spacing: 0.3;
			}
		}
	}
	.ant-col {
		margin-bottom: 10px;
	}
	@media (min-width: 768px) {
		.ant-col-md-12 {
			&:nth-child(1) {
				padding-right: 10px;
			}
			&:nth-child(2) {
				padding-left: 10px;
			}
		}
	}
}

/*-------------------- OVERWRITE ANTD STYLE--------------------*/
.overWtriteAntd {
	.ant-layout-header {
		padding: 0 10px;
		z-index: 2;
	}
	.ant-card-body {
		flex: 1;
		padding: 0;
	}
	.ant-card-bordered {
		border: 0;
		border-radius: 8px;
		// margin: 10px 0;
	}
	.ant-menu,
	.ant-menu-sub.ant-menu-inline {
		background-color: $menuColor;
	}

	.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		border-radius: 8px;
		background-color: $menuSelectColor;
	}
	.ant-menu-item,
	.ant-menu-submenu-title {
		border-radius: 8px;
		margin: 10px;
	}
	.ant-menu-submenu {
		border-radius: 8px;
	}
	.ant-menu-inline .ant-menu-item,
	.ant-menu-inline .ant-menu-submenu-title {
		width: calc(100% - 19px);
	}
	.ant-menu-inline .ant-menu-item-selected::after {
		transform: scaleY(0);
	}
	.ant-menu-item .anticon,
	.ant-menu-submenu-title .anticon {
		color: $menuFontColor;
	}
	.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
	.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
	.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
	.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
	.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
	.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
	.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
	.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
	.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
	.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
	.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
	.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
		background: $menuFontColor;
	}
	.ant-menu-item .anticon + span,
	.ant-menu-item span,
	.ant-menu-submenu-title span {
		font-weight: 400;
		color: $menuFontColor;
	}

	.ant-menu-item-disabled .anticon {
		color: $menuFontDisableColor;
	}
	.ant-menu-item-disabled .anticon + span {
		font-weight: 400;
		color: $menuFontDisableColor;
	}

	.ant-menu-item-selected .anticon {
		vertical-align: -0.17em;
		font-size: 16px;
		color: $menuFontSelectColor;
	}

	.ant-menu-item-selected .anticon + span {
		color: $menuFontSelectColor;
		font-weight: 400;
	}

	.ant-menu-inline,
	.ant-menu-vertical,
	.ant-menu-vertical-left {
		border-right: 1px solid transparent;
	}

	.ant-layout-content {
		overflow: auto;
		min-height: unset;

		&::-webkit-scrollbar-track {
			background-color: transparent;
		}

		&::-webkit-scrollbar {
			width: 10px;
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgba($darkGrayColor, 0.2);
			border-radius: 5px;
		}

		&:hover {
			&::-webkit-scrollbar-thumb {
				background-color: rgba($darkGrayColor, 0.3);
			}
		}
	}

	.ant-checkbox {
		border-radius: 4px;
		overflow: hidden;

		.ant-checkbox-inner {
			border-radius: 4px;
		}
	}
	.ant-table-body {
		border-radius: 10px;

		overflow-y: auto !important;
		overflow-x: auto !important;
	}

	.ant-table-thead > tr > th,
	.ant-table-tbody > tr > td {
		height: 60px; //fix spring size
		border-bottom: 0;
	}
	.ant-table-tbody > tr > td {
		border-top: 1px solid #e8e8e8;
	}

	.ant-form-item-required::before {
		display: none;
	}
	.ant-select-selection {
		// border: 1px solid $menuSelectorBorderColor;
		// color: $menuSelectorColor;
		min-width: 95px;
		// .ant-select-arrow {
		// 	// color: $menuSelectorColor;
		// }
	}

	.ant-drawer-wrapper-body {
		height: 100%;
		overflow: auto;
		overflow-x: hidden;
	}
	.ant-select-selection-selected-value {
		max-width: 150px;
	}
}
.myFilter {
	padding: 0px;
	@media only screen and (max-width: 425px) {
		max-width: 300px;
	}
	.ant-popover-inner-content {
		min-width: 420px;
		@media only screen and (max-width: 425px) {
			min-width: 300px;
		}
		padding: 0px;
	}
	.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
	.ant-popover-arrow {
		display: none;
	}

	.ant-calendar-picker {
		max-width: 60vw;
	}
}

.myInput {
	.ant-form-item-control {
		line-height: unset;
	}
}

.myForm {
	.ant-form-item-label > label {
		font-weight: 500;
	}
}

.isShowIcon {
	.ant-select-selection__rendered {
		margin-left: 29px;
	}
}
.projectSelect {
	display: flex;
	margin: 0 11px 0 11px;
	position: relative;
	color: $grayColor;
}

.scrollbarStyle::-webkit-scrollbar {
	width: 8px;
	background-color: transparent;
}

.scrollbarStyle::-webkit-scrollbar-thumb {
	background-color: rgba($menuFontColor, 0.2);
	border-radius: 10px;
}

// .table-striped-rows tr:nth-child(2n-1) td {
// 	background-color: rgba(49, 128, 207, 0.07);
// }

/*-----------dashboard table-----------------*/
.dashboard-table-row {
	cursor: pointer;
}
.dashboard-table-row-light {
	background-color: #ffffff;
}
.dashboard-table-row-dark {
	// background-color: rgba(49, 128, 207, 0.1);
	background-color: #f8fafd;
}
.dashboard-table-row-selected {
	background-color: rgb(240, 250, 255);
}
/*--------------------Table----------------------------*/
.table-row-light {
	background-color: #ffffff;
	cursor: pointer;
}
.table-row-dark {
	// background-color: rgba(49, 128, 207, 0.1);
	background-color: #f8fafd;
	cursor: pointer;
}
.table-row-selected {
	background-color: rgb(240, 250, 255);
	cursor: pointer;
}

// .ant-table-expanded-row:after tr {
// 	.ant-table-expanded-row-level-1 {
// 		// background-color: rgba(63, 81, 181, 0.07);
// 		background-color: yellow;
// 	}
// 	.ant-table-expanded-row-level-1 td:first-child {
// 		display: none;
// 	}
// }

// .ant-table-expanded-row-level-1 td:first-child {
// 	display: none !important;
// }

.ant-transfer-customize-list .ant-transfer-list {
	-ms-flex: auto;
	flex: auto;
	width: auto;
	height: 500px;
	min-height: 200px;
	overflow: scroll;
}

thead[class*='ant-table-thead'] th {
	background-color: #ffffff !important;
}

.ant-table-row-expand-icon-cell {
	position: relative;

	.ant-table-row-collapsed:after {
		content: '\276F';
		font-family: 'anticon' !important;
		font-style: normal;
		vertical-align: center;
		text-align: center;
		text-transform: none;
		text-rendering: auto;
		// color: $primaryColor;

		right: 16px;
		top: 0;
		// bottom: 10px;
		display: inline-block;
		transform: scale(0.66666667) rotate(0deg);
		transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
		zoom: 1;
	}

	.ant-table-row-expanded:after {
		content: '\276F';
		font-family: 'anticon' !important;
		font-style: normal;
		vertical-align: baseline;
		text-align: center;
		text-transform: none;
		text-rendering: auto;
		// color: $primaryColor;

		right: 16px;
		top: 0;
		display: inline-block;
		transform: rotate(90deg) scale(0.66666667);
		transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
		zoom: 1;
	}
}

.span-tag-online {
	background-color: $onlineColor;
	color: #ffffff;
	border-radius: 15px;
	font-size: 12px;
	text-align: center;
	padding: 2px 5px;
	font-weight: 'bold';
	letter-spacing: 0.25;
	@media only screen and (max-width: 600px) {
		font-size: 10px;
	}
}
.span-tag-offline {
	background-color: $offlineColor;
	color: #ffffff;
	border-radius: 15px;
	font-size: 12px;
	text-align: center;
	padding: 2px 5px;
	font-weight: 'bold';
	letter-spacing: 0.25;
	@media only screen and (max-width: 600px) {
		font-size: 10px;
	}
}

.treeUpdate {
	position: absolute;
	z-index: 5;
	margin: 8px 0 0 200px;
	color: #fff;
	opacity: 0.8;
	font-size: 12.5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.codeUpdate {
	position: absolute;
	z-index: 5;
	margin: 8px 0 0 235px;
	color: #fff;
	opacity: 0.8;
	font-size: 12.5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// ---- transfer -----//
.ant-transfer-list-header {
	height: 40px !important;
	border-bottom: 0px solid #e8e8e8 !important;
	padding: 8px 12px 0px !important;
	font-size: 18px !important;
}
.ant-transfer-list-header-selected .ant-transfer-list-header-title {
	display: block;
	line-height: 1.5;
	left: 12px;
	font-weight: bold;
}
.ant-transfer-list-header-selected span {
	display: none;
}

.ant-transfer-operation button {
	margin-bottom: 30px;
	margin-top: 30px;
}

.ant-transfer-list-body-search-wrapper {
	padding: 0 12px 0 !important;
	display: inline-block;
}
.ant-transfer-list-search-action {
	top: 0 !important;
	right: 16px !important;
}

.p-setting-and-profile {
	font-size: 14;
	font-weight: 500;
	letter-spacing: 0.5;
	color: #4a4a4a;
	text-transform: capitalize;
	margin: 0;
}

.colorBtnSearch {
	.ant-btn-primary {
		color: #bfbfbf;
		background-color: transparent;
		border: solid 1px #d9d9d9;
		text-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		padding: 0 10px;
	}
	.ant-input-search-icon {
		color: #bfbfbf;
		cursor: pointer;
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
	}
	.ant-input {
		color: rgba(0, 0, 0, 0.65);
		background-color: #fff;
		background-image: none;
		border: solid 1px rgba(0, 0, 0, 0.15);
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
	}
	.ant-input-search-icon:hover {
		color: #589edb;
	}
	.ant-btn:hover,
	.ant-btn:focus {
		color: #589edb;
		background-color: transparent !important;
		border-color: #589edb;
	}
}

.colorFilter {
	.ant-btn-background-ghost.ant-btn-primary {
		color: #bfbfbf;
		background-color: transparent;
		border: solid 1px #d9d9d9;
		text-shadow: none;
		box-shadow: none;
	}
}

.cssDrawer {
	color: #fff;
	.ant-drawer-header {
		position: relative;
		padding: 22px 30px;
		color: rgba(0, 0, 0, 0.65);
		background: $menuColor;
		border-bottom: none;
		border-radius: none;
		width: 130px;
	}
	.ant-drawer-content {
		position: relative;
		z-index: 1;
		overflow: auto;
		box-shadow: 2px 0 6px 0 rgba(0, 21, 41, 0.35);
		background-color: $menuColor;
		background-clip: padding-box;
		border: 0;
	}
	.ant-drawer-body {
		padding: 0;
	}
	.ant-menu {
		background: $menuColor;
	}
	.ant-menu-inline,
	.ant-menu-vertical,
	.ant-menu-vertical-left {
		border-right: none;
	}
	.ant-drawer-mask {
		background-color: rgba(0, 0, 0, 0.85);
	}
	.ant-select-arrow {
		color: #3180cf;
	}
	.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background-color: $menuSelectColor;
	}
	.ant-menu-item-selected .anticon {
		vertical-align: -0.17em;
		font-size: 16px;
	}
	.ant-select-selection-selected-value {
		max-width: 150px;
	}
	.ant-menu-vertical .ant-menu-item::after,
	.ant-menu-vertical-left .ant-menu-item::after,
	.ant-menu-vertical-right .ant-menu-item::after,
	.ant-menu-inline .ant-menu-item::after {
		border-right: none;
	}
}

.cssTableTransfer {
	.ant-transfer-customize-list .ant-transfer-list {
		flex: auto;
		width: 100%;
		height: auto;
		min-height: 200px;
		overflow: hidden;
	}
	.ant-table-body {
		overflow: auto;
		max-height: 500px;
		@media only screen and (max-width: 600px) {
			max-height: 130px;
		}
	}

	@media only screen and (max-width: 600px) {
		.ant-transfer-operation .ant-btn .anticon {
			transform: rotate(90deg);
		}
		.ant-transfer-operation .ant-btn {
			display: inline-block;
			margin: 5px 10px 0px 0px;
		}
		.ant-transfer-operation {
			display: flex;
			vertical-align: middle;
			justify-content: center;
		}

		.ant-pagination.mini .ant-pagination-item {
			min-width: 18px;
			height: 18px;
			margin: 0;
			line-height: 16px;
			font-size: 12px;
		}

		.ant-btn-icon-only.ant-btn-sm {
			width: 20px;
			height: 18px;
			padding: 0 1px 0 0;
			font-size: 10px;
			border-radius: 3px;
		}

		.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
			margin-top: -0.6em;
		}

		.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
			margin: 4px 0 4px;
		}

		.ant-transfer-list-search-action {
			line-height: 27px;
		}

		.ant-input {
			height: 25px;
			font-size: 12px;
		}

		.ant-transfer-customize-list .ant-transfer-list-body-customize-wrapper {
			padding-top: 3px;
			padding-bottom: 3px;
		}

		.ant-transfer-list-header-title {
			font-size: 17px;
		}

		.ant-table table {
			font-size: 12px;
		}

		.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
			padding: 5px 5px;
		}

		.ant-pagination-prev .ant-pagination-item-link,
		.ant-pagination-next .ant-pagination-item-link {
			font-size: 11px;
		}
	}
}

.modalTransfer {
	.ant-modal-body {
		@media only screen and (max-width: 600px) {
			padding-top: 10px;
			padding: 16px;
		}
	}
	.ant-modal-header {
		padding: 16px 24px 0;
		border-bottom: 0;
		@media only screen and (max-width: 600px) {
			padding: 16px 16px 0;
		}
	}
	.ant-modal-footer {
		background: $primaryFadeColor;
		border-top: 0;
	}
	.ant-modal-centered,
	.ant-modal {
		width: 80%;
		@media only screen and (max-width: 600px) {
			width: 100%;
			padding: unset;
			max-width: unset;
			margin: unset;
		}
	}
}
.tips-modal {
	.ant-select-selection {
		border: 0;
		margin-left: 0;
	}
	.ant-select-selection--single .ant-select-selection__rendered {
		margin-right: 35px;
	}
	.ant-select-selection__rendered {
		margin-left: 0;
	}
	.ant-modal-body {
		padding: 0 24px 24px;
	}
}

.underDiv {
	padding-bottom: 8px;
	font-size: 12px;
	@media only screen and (max-width: 600px) {
		padding-bottom: 3px;
		font-size: 10px;
	}
}

/*--------json editor---------*/
.jsoneditor-menu button {
	cursor: pointer;
}

/*-------- chart ---------*/
.apexcharts-tooltip {
	background-color: #fff;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
}
.primary-select-arrow {
	.ant-select-arrow {
		color: $menuSelectColor !important;
		fill: $menuSelectColor !important;
	}
}
