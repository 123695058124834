$primaryColor: #3180cf; // primary color for all components
$linkColor: #3180cf; // link color
$successColor: #52c41a; // success state color
$warningColor: #ffcb02; // warning state color
$errorColor: #ff0000; // error state color

$darkGrayColor: #323643;
$grayColor: #606470;
$lightGrayColor: #f8fbfc;

$onlineColor: #10ca88;
$offlineColor: #9b9b9b;

$primaryFadeColor: rgba($primaryColor, 0.1);

// $menuColor: #3080ce;
// $menuSelectColor: #0960ba;
// $menuFontColor: #fff;
// $menuFontSelectColor: $lightGrayColor;
// $menuFontDisableColor: $grayColor;
// $menuSelectorColor: $primaryColor;
// $menuSelectorBorderColor: rgb(0,0,0);
// $menuLinkColor: #fff;

$menuColor: #4a7ccc;
$menuSelectColor: rgba(#ffffff, 0.2);
$menuFontColor: #fff;
$menuFontSelectColor: #fff;
$menuFontDisableColor: #606470;
$menuSelectorBorderColor: #fff;
$menuSelectorColor: #000;
$menuLinkColor: #fff;

// $themeBackground: rgba(238, 240, 248, 0.8);
$themeBackground: #f5f6fb;

:export {
	primaryColor: $primaryColor;
	linkColor: $linkColor;
	successColor: $successColor;
	warningColor: $warningColor;
	errorColor: $errorColor;
	darkGrayColor: $darkGrayColor;
	grayColor: $grayColor;
	lightGrayColor: $lightGrayColor;

	onlineColor: $onlineColor;
	offlineColor: $offlineColor;

	primaryFadeColor: $primaryFadeColor;

	menuColor: $menuColor;
	menuSelectColor: $menuSelectColor;
	menuFontColor: $menuFontColor;
	menuFontSelectColor: $menuFontSelectColor;
	menuFontDisableColor: $menuFontDisableColor;
	menuSelectorBorderColor: $menuSelectorBorderColor;
	menuSelectorColor: $menuSelectorColor;
	menuLinkColor: $menuLinkColor;

	themeBackground: $themeBackground;
}
